import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Man</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Man </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <h2 className="playfair text-black text-xl md:text-3xl">Personality Traits</h2>{" "}<br/>
                  Hardworking, intelligent, perfectionist, problem-solver, and
                  adventurous.<br/>
                  <br></br>
                  <p className="playfair text-black text-xl md:text-3xl">
                    Compatibility Zodiac Signs for Virgo men
                  </p>{" "}<br/>
                  Gemini, cancer, Capricorn, Taurus, and Sagittarius
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Brief about Virgo men</p><br/>
                  Virgo men are one of the most challenging zodiac signs to get
                  to know. Unlike other signs, there is no one-size-fits-all
                  description of what a Virgo man is like. The thing that makes
                  Virgo men so unique is their personality. They can be
                  introverts or extroverts, and they can be sweet romantics or
                  brooding loners.
                  <br />
                  <br />
                  Virgo men are known for being hard on themselves and others.
                  They can be insecure and picky and sometimes rigid about
                  things. But they're also known for being good listeners,
                  gentle and kind, and providing excellent advice and guidance
                  when needed. Virgo men may not be the most exciting. Still,
                  they're often the ones who have the most respect and
                  appreciation for other people.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Let's take a look at Virgo men's traits.
                  </p>
                  <br/>
                  We’ll look at some of the significant Virgo traits and how
                  best to relate to them. <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Hard worker:</p> You're hardworking perfectionists who constantly
                  strive to improve themselves. You are calm, cool, and
                  collected; you don't sweat the small stuff and are always
                  ready to tackle a new challenge. You're excellent listeners
                  who are sensitive to other people's needs.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Shy in nature:</p> You guys don't understand how to flirt. You're
                  being quiet, shy, and introspective, and when it comes to the
                  art of flirting, you can't fake it. Virgo men are so used to
                  being rejected that they don't bother trying. But the truth
                  is, if you can get through their tough exterior and get them
                  to open up, you will find that Virgo men are so sweet and kind
                  and make for the best boyfriends.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Kind and straightforward: </p> On the surface, they appear simple,
                  but once you get to know them, you realize they're as deep as
                  the ocean. Virgo men are highly complex, which means their
                  personalities are also highly problematic. They're kind.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Loyal:</p> Virgo men are known for being hardworking, loyal, and
                  fair. They seek out the truth and are committed to doing the
                  right thing. Men are also known for being critical and
                  faultfinding, which is a tendency that often causes them to
                  overthink and worry. They can also be indecisive, which stems
                  from their desire to get things right.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Problem solver:</p> You feel like you're stuck trying to find a
                  solution to a problem. You may retreat into your comfort zone
                  and refuse to accept help when this happens. <br />
                  <br />
                  You like to think that you can handle things independently,
                  but in reality, you need other people to help you through
                  tough times. You are a problem solver, and you thrive when you
                  can apply your skills to a challenge.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Adventurous:</p> Virgo men love adventures — the more unusual and
                  challenging, the better. They seek out new experiences and are
                  often some of the most exciting and active people. They have a
                  bustling mind and are constantly thinking about what they want
                  to be doing five years from now.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Intelligent:</p> Virgo men can be hard to read sometimes. They can
                  be quiet and introspective, but they're also knowledgeable and
                  wise. They are also very hardworking, which makes them
                  excellent partners and fathers. They are very dependable,
                  which makes them great to have in your life when you need
                  help.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Reliable:</p> You are known for being extremely hardworking and
                  dedicated. You take great pride in your work and are thorough,
                  precise, and detail-oriented. You are also known for being
                  introverted, sensitive, and empathetic. You know ad understand
                  people thought processes, and that's why you are great
                  listeners and can empathize with other people's emotions and
                  experiences.
                  <br />
                  <br />
                  These personality traits manifest themselves positively, such
                  as being respectful, thoughtful, and empathetic, while
                  negative features like being judgmental, critical, and gossipy
                  also characterize Virgo men. Regardless of the direction of
                  the trait, Virgo men possess an inherent sense of dignity and
                  a desire to be of service to others. Emotions are often tricky
                  for Virgo men to express, but they are deeply in tune with the
                  feelings of others.
                  <br />
                  <br />
                  How Virgo men are in love and relationship? Virgo men are some
                  of the most romantic and affectionate men you will ever meet.
                  They love being romantic and will often take great pains to
                  show you how much they care about you. They are also some of
                  the most affectionate and touchy-feely men you will ever meet,
                  which can be a blessing or a curse, depending on how you feel
                  about being touched by your partner!
                  <br />
                  <br />
                  When they commit to a relationship, they are fiercely loyal
                  and protective. Virgos are some of the most dependable people
                  you will ever meet, making them easy to love. Still, it also
                  makes them challenging to get rid of when they get attached.
                  <br />
                  <br />
                  In love and relationships, Virgo men are some of the most
                  mysterious and contradictory signs in the zodiac. But don't
                  let their mysteriousness fool you: Virgo men are some of the
                  most affectionate and passionate men you will ever meet.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    How to attract Virgo men?
                  </p>
                  <br/>
                  Virgo individuals are obsessed with being polite and being on
                  time. <br />
                  They are very particular about these things, so show up early
                  for meetings, keep meetings on schedule, and be punctual for
                  dates and other events. <br />
                  If you value their time, they will be interested in you and
                  want to get to know you better. Virgo people are very
                  well-mannered, so it's essential to show up in a nice outfit.
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
